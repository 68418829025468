<script setup lang="ts">
import {
  ChatCarrier,
  ChatHeadline,
  Content,
  Message,
  SelectedAnswersEmit,
} from '~/modules/chat-ad'
import { isMobile } from '~/modules/isMobile'

import chatAudioMessage from '~/assets/audios/chat-ad/chat-delay-message.mp3'
import medicareCard from '~/assets/card-medicare-no-shadow.png'
import emilyNewAvatar from '~/assets/emily-b008.png'
import emilyWithGroceries from '~/assets/emily-w-groceries.png'
import { PlanRowView } from '~/modules/plan'

const isPaidUser = ref(true)

const matches = ref(false)

const plans = ref<PlanRowView[]>([])
const loading = ref<boolean>(true)
const error = ref<boolean>(false)
const top5Otc = ref<number>(0)
const selectedAnswers = ref<SelectedAnswersEmit | null>(null)
const showZipCodeInput = ref(true)
const zipCode = ref<string | null>('')
const carriers = ref<ChatCarrier[]>([])

const afid = computed(() => {
  return typeof phoneConfig.value === 'object' && 'afid' in phoneConfig.value
    ? phoneConfig.value.afid
    : undefined
})

const queryAfid = ref<string | null>()

onMounted(() => {
  queryAfid.value = getSessionEnhanced().query.afid
})

const state = computed(() => {
  const state = states.find((state) => state.code === ip.lookup?.stateCode)

  if (state) {
    return state
  }
  return {
    name: 'your area',
    medicaid: 'Medicaid',
    top5Otc: 0,
  }
})

onMounted(async () => {
  matches.value = window.matchMedia('(prefers-color-scheme: dark)').matches
  if (matches.value) {
    document.documentElement.classList.add('dark')
  } else {
    document.documentElement.classList.remove('dark')
  }

  isPaidUser.value = getSessionEnhanced().isPaidUser

  await until(() => ip.lookup).toBeTruthy({ timeout: 5000 })

  zipCode.value = query.zip_code ?? ip.lookup?.postalCode ?? null

  enterExperiment('chatScript')

  if (zipCode.value) {
    showZipCodeInput.value = false
    try {
      await getPageData(zipCode.value)
    } catch (e) {
      error.value = true
    } finally {
      loading.value = false
    }
  } else {
    top5Otc.value = state.value?.top5Otc || 0
    showZipCodeInput.value = true
    loading.value = false
  }
})

const getPageData = async (zip: string) => {
  zipCode.value = zip
  try {
    plans.value = await getPlans({
      zipCode: zip,
      carrier_groups: [],
      amount: 1000,
      starting_from: 0,
    })
    await getTop5OtcData(zip, false)
  } catch (e) {
    error.value = true
  } finally {
    loading.value = false
    showZipCodeInput.value = false
  }
}

const getTop5OtcData = async (zip: string, is_dsnp: boolean) => {
  const response = await getTop5Otcs({
    zip_code: zip,
    is_dsnp: is_dsnp,
  })

  top5Otc.value = response.min_otc
    ? parseInt(`${response.min_otc}`, 10)
    : state.value?.top5Otc || 0
}

analytics.page({
  name: 'Chat Ad Page',
  category: 'LP',
  product: 'medicare',
})

const chatHeadline: ChatHeadline = {
  image: emilyNewAvatar,
  title: 'Benefit Advisor',
  subtitle: 'Ask me about your Medicare Benefits!',
  advisorName: 'Emily',
}
const afidsRedirectToClicks = [
  '811301',
  '811326',
  '811310',
  '811325',
  '811329',
  '811171',
  '811276',
  '811322',
  '811284',
  '811328',
  '811293',
  '811311',
]

const destination = computed(() => {
  const afidValue = queryAfid.value || afid.value
  if (afidsRedirectToClicks.some((a) => a === afidValue)) {
    return '/ads'
  }
  return null
})

const onSelectAnswer = async (
  nextStepId: string,
  selected: SelectedAnswersEmit,
  callback: (response: Message[]) => void
) => {
  selectedAnswers.value = {
    ...selectedAnswers.value,
    ...selected,
  }
  if (selected.haveMedicaid && selected.haveMedicaid === true) {
    const haveMedicaid = selected.haveMedicaid

    if (haveMedicaid === true && zipCode.value) {
      try {
        await getTop5OtcData(zipCode.value, haveMedicaid)
      } catch (e: any) {
        console.error('[DEBUG] error on retrieving new value for top5otc', {
          error: e.message,
        })
      }
    }
  }

  if (selected.zipcode) {
    zipCode.value = selected.zipcode
    await getPageData(selected.zipcode)
  }

  if (nextStepId === 'secondOpinion') {
    const data = await getTransferPhoneNumbers(afid, zipCode.value)
    carriers.value = data
  }

  const messages = getMessageBlockById(nextStepId)
  return callback(messages)
}

const getMessageBlockById = (stepId: string): Message[] => {
  return content.value.messageBlocks?.[stepId] ?? null
}

const content = computed<Content>(() => {
  const medicareStatus = selectedAnswers.value?.haveMedicare ? 'Yes' : 'No'

  return {
    messageBlocks: {
      init: [
        {
          type: 'text',
          content: 'Hi!',
          from: 'emily',
          shouldShowAvatar: true,
        },
        {
          type: 'text',
          content: `I'm Emily from ${brand.name}!`,
          from: 'emily',
        },

        showZipCodeInput.value
          ? {
              type: 'text',
              content: `<span class="font-semibold">So I can give you personal results, can you please tell me your zipcode</span>`,
              from: 'emily',
            }
          : null,
        showZipCodeInput.value
          ? {
              type: 'zip',
              answers: [
                {
                  text: 'Submit',
                  answer: 'secondBlock',
                  event: { property: 'zipcode', value: null },
                },
              ],
            }
          : {
              type: 'redirect',
              from: 'emily',
              answers: [
                {
                  text: 'Submit',
                  answer: 'secondBlock',
                  event: { property: 'zipcode', value: null },
                },
              ],
            },
      ],
      secondBlock: [
        isPaidUser.value && isMobile
          ? exp.value?.chatScript?.variantA
            ? {
                type: 'buttons',
                content: `<span class="font-bold">Want to find out if you qualify for a grocery allowance of ${USDollarFormatter(
                  0
                ).format(top5Otc.value)} or more? Tap Yes! 😃</span>`,
                from: 'emily',
                answers: [
                  {
                    text: 'Yes',
                    answer: 'medicareab',
                    event: { property: 'groceryAllowance', value: true },
                  },
                ],
                clicked: false,
                trackPayload: {
                  top5Otc: top5Otc.value,
                },
              }
            : {
                type: 'image',
                image: emilyWithGroceries,
                from: 'emily',
              }
          : null,
        isPaidUser.value && isMobile && !exp.value?.chatScript?.variantA
          ? {
              type: 'buttons',
              content: `<span class="font-bold">Get your ${USDollarFormatter(
                0
              ).format(top5Otc.value)}/yr+ allowance card yet?</span>`,
              from: 'emily',
              answers: [
                {
                  text: 'Yes 👍',
                  answer: 'medicareab',
                  event: { property: 'hasAllowanceCard', value: true },
                },
                {
                  text: 'No',
                  answer: 'medicareab',
                  event: { property: 'hasAllowanceCard', value: false },
                },
              ],
              clicked: false,
            }
          : null,
        !isPaidUser.value || !isMobile
          ? {
              type: 'buttons',
              content: `<span class="font-bold">Want to learn about Medicare Advantage in ${state.value.name}?</span>`,
              from: 'emily',
              answers: [
                {
                  text: 'Yes 👍',
                  answer: 'medicareab',
                  event: { property: 'learnMoreMedicare', value: true },
                },
              ],
              clicked: false,
            }
          : null,
      ],
      medicareab: [
        selectedAnswers.value?.groceryAllowance === true ||
        selectedAnswers.value?.hasAllowanceCard === true ||
        selectedAnswers.value?.learnMoreMedicare === true
          ? {
              type: 'text',
              content: 'Yes',
              from: 'user',
            }
          : null,
        selectedAnswers.value?.hasAllowanceCard === false
          ? {
              type: 'text',
              content: 'No',
              from: 'user',
            }
          : null,
        {
          type: 'text',
          content:
            '<span class="font-bold">Do you have this card? (Are you on Medicare)</span>',
          from: 'emily',
        },
        {
          type: 'image',
          image: medicareCard,
          from: 'emily',
        },
        {
          type: 'buttons',
          content: '',
          from: 'emily',
          answers: [
            {
              text: 'Yes',
              answer: 'medicaid',
              event: { property: 'haveMedicare', value: true },
            },
            {
              text: 'No',
              answer: 'medicaid',
              event: { property: 'haveMedicare', value: false },
            },
          ],
          clicked: false,
        },
      ].filter(Boolean),
      medicaid: [
        {
          type: 'text',
          content: medicareStatus,
          from: 'user',
        },
        {
          type: 'buttons',
          content: `<span class="font-bold">Are you receiving benefits from Medicaid${
            state.value.medicaid !== 'Medicaid'
              ? `, also known as ${state.value.medicaid}`
              : ''
          }?</span>`,
          from: 'emily',
          answers: [
            {
              text: 'Yes',
              answer: selectedAnswers.value?.haveMedicare
                ? 'enrollBranch'
                : 'noMedicareAB',
              event: { property: 'haveMedicaid', value: true },
            },
            {
              text: 'No',
              answer: selectedAnswers.value?.haveMedicare
                ? 'enrollBranch'
                : 'noMedicareAB',
              event: { property: 'haveMedicaid', value: false },
            },
          ],
          clicked: false,
        },
      ],
      noMedicareAB: [
        {
          type: 'text',
          content: selectedAnswers.value?.haveMedicaid ? 'Yes' : 'No',
          from: 'user',
        },
        {
          type: 'text',
          content: 'Hmmm',
          from: 'emily',
        },
        {
          type: 'text',
          content: `You were asking about a Medicare benefit. It doesn't look like you have Medicare.`,
          from: 'emily',
        },
        {
          type: 'buttons',
          content: `<span class="font-bold">Are you turning 65 or signing up for Medicare A&B in the next 6 months?</span>`,
          from: 'emily',
          answers: [
            {
              text: 'Yes',
              answer: 'enrollBranch',
              event: { property: 'turning65', value: true },
            },
            {
              text: 'No',
              answer: 'notTurning65',
              event: { property: 'turning65', value: false },
            },
          ],
          clicked: false,
        },
      ],
      notTurning65: [
        {
          type: 'text',
          content: 'No',
          from: 'user',
        },
        {
          type: 'text',
          content: `Click below to learn about ACA plans that may be a better fit for your needs.`,
          from: 'emily',
        },
        {
          type: 'redirectButton',
          content: `Learn More`,
          from: 'emily',
          ctaUrl: '/go-4-benefits',
        },
      ],
      enrollBranch: [
        {
          type: 'text',
          content: selectedAnswers.value?.haveMedicaid ? 'Yes' : 'No',
          from: 'user',
        },
        {
          type: 'text',
          content: `Good News!`,
          from: 'emily',
        },
        {
          type: 'text',
          content: `It looks like you are eligible!`,
          from: 'emily',
        },
        isPaidUser.value && isMobile
          ? {
              type: 'text',
              content: `I found plans near you with ${USDollarFormatter(
                0
              ).format(top5Otc.value)}/yr+ in grocery allowance benefits!`,
              from: 'emily',
            }
          : {
              type: 'text',
              content: `I found ${plans.value.length} plans near you!`,
              from: 'emily',
            },
        {
          type: 'buttons',
          content: `<span class="font-bold">Would you be willing to talk on the phone to sign up?</span>`,
          from: 'emily',
          answers: [
            destination.value !== null
              ? {
                  text: 'Yes',
                  answer: 'redirectToClicks',
                  event: { property: 'willingToTalkPhone', value: true },
                }
              : {
                  text: 'Yes',
                  answer: 'callNow',
                  event: { property: 'willingToTalkPhone', value: true },
                },
            {
              text: 'No',
              answer: 'enrollOnline',
              event: { property: 'willingToTalkPhone', value: false },
            },
          ],
          clicked: false,
        },
      ],
      redirectToClicks: [
        {
          type: 'redirectButton',
          content: `Shop Plans`,
          from: 'emily',
          ctaUrl: '/ads',
        },
      ],
      callNow: [
        {
          type: 'text',
          content: `Yes`,
          from: 'user',
        },
        {
          type: 'callButton',
          from: 'emily',
          shouldShowReminder: true,
        },
      ],
      enrollOnline: [
        {
          type: 'text',
          content: `No `,
          from: 'user',
        },
        {
          type: 'text',
          content: `No Problem!`,
          from: 'emily',
        },
        {
          type: 'text',
          content: `I've found a few options that you can explore online`,
          from: 'emily',
        },
        {
          type: 'redirectButton',
          content: `Shop Plans`,
          from: 'emily',
          ctaUrl: '/ads',
        },
      ],
      reminder: [
        {
          type: 'audio',
          content: '',
          audio: {
            src: chatAudioMessage,
            autoplay: true,
          },
          from: 'emily',
        },
      ],
      welcomeBack: [
        {
          type: 'text',
          content: 'Welcome back 👋',
          from: 'emily',
        },

        {
          type: 'buttons',
          content: 'How did your call go?',
          from: 'emily',
          answers: [
            {
              text: 'Good 👍',
              answer: 'goodCall',
              event: { property: 'goodCall', value: true },
            },
            {
              text: 'Bad 👎',
              answer: 'badCall',
              event: { property: 'goodCall', value: false },
            },
          ],
          clicked: false,
        },
      ],
      goodCall: [
        {
          type: 'text',
          content: 'Good',
          from: 'user',
        },
        {
          type: 'text',
          content: 'Happy to hear that.',
          from: 'emily',
        },

        {
          type: 'buttons',
          content: 'Did you find what were looking for?',
          from: 'emily',
          answers: [
            {
              text: 'Yes 👍',
              answer: 'whatToDoNext',
              event: { property: 'foundWhatWasLookingFor', value: true },
            },
            {
              text: 'No 👎',
              answer: 'didntFindAfterGoodCall',
              event: { property: 'foundWhatWasLookingFor', value: false },
            },
          ],
          clicked: false,
        },
      ],
      badCall: [
        {
          type: 'text',
          content: selectedAnswers.value?.goodCall ? 'Good' : 'Bad',
          from: 'user',
        },
        {
          type: 'text',
          content: `I'm really sorry.`,
          from: 'emily',
        },
        {
          type: 'buttons',
          content: 'what went wrong?',
          from: 'emily',
          answers: [
            {
              text: 'Bad experience',
              answer: 'whatToDoNext',
              event: { property: 'badExperience', value: true },
            },
            {
              text: `They didn't have what I wanted`,
              answer: 'whatToDoNext',
              event: { property: 'foundWhatWasLookingFor', value: false },
            },
          ],
          clicked: false,
        },
      ],
      didntFindAfterGoodCall: [
        {
          type: 'text',
          content: `No`,
          from: 'user',
        },
        {
          type: 'text',
          content: `I'm really sorry.`,
          from: 'emily',
        },
        {
          type: 'buttons',
          content: 'What went wrong?',
          from: 'emily',
          answers: [
            {
              text: 'Bad experience',
              answer: 'whatToDoNext',
            },
            {
              text: `They didn't have what I wanted`,
              answer: 'whatToDoNext',
            },
          ],
          clicked: false,
        },
      ],
      didntFind: [
        {
          type: 'text',
          content: `They didn't have what I wanted`,
          from: 'user',
        },
        {
          type: 'text',
          content: `I'm really sorry.`,
          from: 'emily',
        },
        {
          type: 'buttons',
          content: 'What went wrong?',
          from: 'emily',
          answers: [
            {
              text: 'Bad experience',
              answer: 'whatToDoNext',
            },
            {
              text: `They didn't have what I wanted`,
              answer: 'whatToDoNext',
            },
          ],
          clicked: false,
        },
      ],
      whatToDoNext: [
        selectedAnswers.value?.foundWhatWasLookingFor
          ? {
              type: 'text',
              content: 'Yes',
              from: 'user',
            }
          : {
              type: 'text',
              content: `They didn't have what I wanted`,
              from: 'user',
            },
        {
          type: 'buttons',
          content: 'What do you want to do next?',
          from: 'emily',
          answers: [
            {
              text: 'Get a second opinion',
              answer: 'secondOpinion',
              event: { property: 'getSecondOpinion', value: true },
            },
            {
              text: `Explore Plans Online`,
              answer: '',
              type: 'redirect',
              ctaUrl: '/ads',
              event: { property: 'explorePlansOnline', value: true },
            },
          ],
          clicked: false,
        },
      ],
      secondOpinion:
        carriers.value && carriers.value.length > 0
          ? [
              {
                type: 'carriers',
                content:
                  carriers.value && carriers.value.length > 0
                    ? 'Nice! You can call one of the following carriers:'
                    : `Sorry, I couldn't find any other carriers available now.`,
                from: 'emily',
                carriers: carriers.value,
              },
            ]
          : [
              {
                type: 'text',
                content: `Sorry, I couldn't find any other carriers available now.`,
                from: 'emily',
              },
              {
                type: 'text',
                content: `But I've found a few options that you can explore online`,
                from: 'emily',
              },
              {
                type: 'redirectButton',
                content: `Shop Plans`,
                from: 'emily',
                ctaUrl: '/ads',
              },
            ],
    },
    configuration: {
      id: 'chatAd',
      initialBlockId: 'init',
      reminder: {
        reminderBlockId: 'reminder',
        reminderTimeout: 10000,
      },
      postCallId: 'welcomeBack',
    },
  } as Content
})
</script>

<template>
  <Layout
    :hide-header="true"
    :hide-footer="true"
    class="bg-gradient-to-b from-white to-gray-100 dark:from-[#1f1f1f] dark:to-[#2d2d2d]"
  >
    <span
      class="absolute text-xs top-2 right-2 border border-black p-1 rounded bg-white"
    >
      Ad
    </span>
    <template #banner>
      <CountdownHeader />
    </template>
    <Chat
      v-if="content && !loading && !error"
      :content="content"
      :headline="chatHeadline"
      @on-select-answer="onSelectAnswer"
      :get-message-block-by-id="getMessageBlockById"
    />
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "brands": ["bridge", "bh", "m10"],
    "robots": "noindex"
  }
}
</route>
